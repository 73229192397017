import React from 'react';
import {WidgetBlockStatus} from "../../../components/Widget/types";
import {Box, Button, Divider, List, Text} from "@mantine/core";
import {ExternalLink} from "tabler-icons-react";

interface WidgetCardButtonErrorProps {
  appEmbedDisabled?: boolean;
  widgetTitle: string;
  widgetBlockStatuses: WidgetBlockStatus[];
}

export const WidgetCardButtonError: React.FC<WidgetCardButtonErrorProps> = ({
                                                                              widgetTitle,
                                                                              widgetBlockStatuses,
                                                                              appEmbedDisabled
                                                                            }) => {
  const errors: string[] = []
  if (appEmbedDisabled) {
    errors.push('App Embed is disabled')
  }
  const isWidgetNotInstalled = widgetBlockStatuses.some((status) => status === WidgetBlockStatus.notInstalled)
  if (isWidgetNotInstalled) {
    errors.push('Widget is not installed')
  }
  const isWidgetHidden = widgetBlockStatuses.some((status) => status === WidgetBlockStatus.widgetIsHidden)
  const isWidgetParentHidden = widgetBlockStatuses.some((status) => status === WidgetBlockStatus.parentBlockIsHidden)
  const textAboutHidden = `The widget block or parent block is hidden in the Shopify Theme. Enable the widget or parent block in the Shopify Theme to preview and publish the widget on-site`
  const isShowHiddenWarning = isWidgetHidden || isWidgetParentHidden

  return (
    <Box>
      {errors.length > 0 && <List>
        {errors.map((warning) => (
          <List.Item key={warning}>{warning}</List.Item>
        ))}
      </List>}
      {(isShowHiddenWarning && errors.length > 0) && <Divider/>}
      {isShowHiddenWarning && (
      <Box>
        <Text>
          {textAboutHidden}
        </Text>
      </Box>
      )}
    </Box>
  );
};
