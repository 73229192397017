import { FC } from 'react';
import { BuyButtonKey, BuyButtonValue } from 'src/components/Widget/store';
import { WidgetSettings } from 'src/components/Widget/types';
import propWrapperStyles from '../PropWrapper.module.scss';
import { ColorInput, NumberInput, Slider, Box } from '@mantine/core';
import styles from '../WidgetLayoutSettings/CarouselLayoutSettings.module.scss';

interface SubmoduleProps {
  handler: (key: BuyButtonKey, value: BuyButtonValue) => void;
  buyButton: WidgetSettings['buyButton'];
}

export const BorderSubmodule: FC<SubmoduleProps> = ({ handler, buyButton }) => {
  return (
    <Box className={propWrapperStyles.submodule__container}>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Color</span>
        <ColorInput
          w={171}
          format="hex"
          placeholder="Pick a color"
          value={buyButton?.borderColor}
          onChange={(val) => handler('borderColor', val)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Thickness</span>
        <div className={styles.slider}>
          <Slider
            value={buyButton?.borderThickness}
            onChange={(val) => handler('borderThickness', val)}
            w={160}
            mr={12}
            max={10}
            min={0}
          />
          <NumberInput
            color="green"
            value={typeof buyButton?.borderThickness === 'number' ? buyButton.borderThickness : 0}
            onChange={(val) => handler('borderThickness', val)}
            hideControls
            styles={() => ({
              input: { width: '42px', height: '26px', minHeight: '26px' },
            })}
            min={0}
            max={10}
            step={1}
            mr={6}
          />
          <div className={styles.slider__units}>px</div>
        </div>
      </Box>
    </Box>
  );
};
