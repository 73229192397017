import {AxiosRequestConfig} from "axios";
import {apiClient} from "../client";

interface Config extends AxiosRequestConfig{
  widgetId: string;
  params: {
    collectionId?: string;
    productId?: string;
  }
}

export type PlatformGetWidgetVideosResponse = {
  id: string;
  mux: string | null;
  preview: string | null;
  gif: string | null;
  type: 'Instagram' | 'Upload';
  title: string;
  duration: string | null;
  mp4_support: boolean;
  url: string | null;
}[]

export const getWidgetVideos = async ({widgetId,...config}: Config) => {
  return apiClient.get<PlatformGetWidgetVideosResponse>(`/bff/widgets/${widgetId}/videos`,config)
}
