import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fontsource/open-sans';
import App from './app/App';
import * as Sentry from "@sentry/react";
import {getEnv} from "./shared/lib/getEnv";
export const baseURL = getEnv('REACT_APP_API_HOST') || '/';

Sentry.init({
  dsn: "https://2570f547a6672534e9edf8a4c346c0f0@o4507329822523392.ingest.de.sentry.io/4508442860978256",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: ["localhost", baseURL, 'https://appapi.heymantle.com'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: window.location.hostname,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", baseURL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
