import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getUser} from "../../../api/me/getUser";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {userActions} from "../../../components/User/store";

export const useGetUser = () => {
  const dispatch = useDispatch();
  const user =  useQuery({
    queryKey: queryKey.user,
    queryFn: ()=> getUser().then(res => res.data),
  })
  useEffect(() => {
    const isFBReview = user.data?.myshopify_domain === 'chatpoint-fb.myshopify.com' || user.data?.myshopify_domain?.includes('fb-test');
    dispatch(userActions.setIsFBReview(isFBReview ?? false))
  }, [user])
  return user;
}
