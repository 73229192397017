import {Box, Center, Loader, Text} from '@mantine/core';
import styles from '../new/NewWidget.module.scss';
import { WidgetCreationHeader } from 'src/components/Widget/WidgetCreationHeader/WidgetCreationHeader';
import { SetupStep } from 'src/components/Widget/Steps/Setup/SetupStep';
import { SetupTabs } from 'src/components/Widget/types';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import { widgetsActions } from 'src/components/Widget/store';
import {useGetSingleWidget} from "../../../shared/hooks/tanstackQuery/useGetSingleWidget";

const getDefaultTabValue = (searchParams: URLSearchParams)  => {
  const defaultTab = searchParams.get('defaultTab');

  if (defaultTab && defaultTab in SetupTabs) {
    return defaultTab as SetupTabs;
  }

  return SetupTabs.settings
}

export const EditWidget = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<SetupTabs>(getDefaultTabValue(searchParams));

  const activeWidget = useSelector(selectActiveWidget);
  const changeTab = (tab: SetupTabs) => setCurrentTab(tab);
  const params = useParams();
  const {data: widget, isLoading: widgetLoading, isError} = useGetSingleWidget({
    widgetId: params.widget_id,
    enabled: !activeWidget && !!params.widget_id
  })

  useEffect(() => {
    dispatch(widgetsActions.setActiveWidget(widget ?? null));
  }, [widget]);



  useEffect(() => {
    if(activeWidget?.id && !activeWidget.productDetails){
      // dispatch(widgetsActions.fetchWidgetProductDetailsSaga({
      //   widgetId: activeWidget.id,
      //   callback: {
      //     onSuccess: (productDetails)=>{
      //       dispatch(widgetsActions.setProductDetails(productDetails));
      //     }
      //   }
      // }));
    }
  }, [activeWidget?.id]);

  return (
    <div className={styles.container}>
      {widgetLoading ? (
        <Center h="100%">
          <Loader size="xl" />
        </Center>
      ) : isError ? (
        <Box>
          <Box px={24} py={20}>
            <Text component="h2" color="white">
              Something went wrong...
            </Text>
          </Box>
        </Box>
      ) : (
        <>
          <WidgetCreationHeader isEditPage openInstallTab={() => setCurrentTab(SetupTabs.installation)} />
          <div className={styles.pageContent}>
            <Box
              sx={() => ({
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'hidden',
                boxSizing: 'border-box',
              })}
            >
              <SetupStep currentTab={currentTab} changeTab={changeTab} />
            </Box>
          </div>
        </>
      )}
    </div>
  );
};
