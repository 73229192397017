import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

export type CheckInstallationResponse = {
  status: 'OK' | 'notOK';
  activation_url: string;
};

export const useActiveWidget = (callback: () => Promise<AxiosResponse>, nextStep: () => void) => {
  const [data, setData] = useState<CheckInstallationResponse | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    callback().then(({ data }) => {
      if (data?.status === 'OK') {
        nextStep();
        return;
      }
      setData(data);
    });
  }, []);

  const activateWidget = () => {
    console.log('data', data)
    if (!data?.activation_url) return;
    window.open(data.activation_url);
  };

  const nextStepHandler = async () => {
    setError(false);
    try {
      await callback().then(({ data }) => {
        if (data.status === 'OK') {
          nextStep();
          return;
        } else {
          setError(true);
        }
      });
    } catch (e) {
      setError(true);
    }
  };

  return {
    error,
    data,
    activateWidget,
    nextStepHandler,
  };
};
