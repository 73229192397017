/**
 * Sets a value at a specified dot-separated path within an object immutably.
 * @param obj - The original object.
 * @param path - The dot-separated path string (e.g., "Header.text").
 * @param value - The value to set at the specified path.
 * @returns A new object with the updated value.
 */
export function setNestedValue<Obj, ObjPath extends string = string, Value = unknown>(
  obj: Obj,
  path: ObjPath,
  value: Value,
): Obj {
  try {
    if (!path) return obj;

    const keys = path.split('.');
    const newObj = {...obj};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let current: any = newObj;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (i === keys.length - 1) {
        current[key] = value;
      } else {
        current[key] = {...(current[key] || {})};
        current = current[key];
      }
    }

    return newObj;
  } catch (e) {
    console.error('Error in setNestedValue:', e);
    return obj;
  }
}
