import { ColorInput, NumberInput, Slider, Switch } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { SegmentSwitch } from 'src/components/SegmentSwitch/SegmentSwitch';
import { PropWrapper } from '../PropWrapper';
import { PropWithSwitchAutoScroll } from '../PropWithSwitchAutoScroll/PropWithSwitchAutoScroll';
import styles from './CarouselLayoutSettings.module.scss';
import {
  LayoutKey,
  LayoutKeyV2,
  LayoutValue,
  LayoutValueV2,
  SetLayoutV2ActionPayload,
  widgetsActions, widgetSettingsInit
} from 'src/components/Widget/store';
import {
  selectWidgetSettings,
  selectWidgetSettingsV2,
} from 'src/components/Widget/selectors';
import {BorderSubmodules, HeadingSubmodule, PreviewBuyButtonSubmodule} from './CarouselLayoutSettings.submodules';
import {PreviewCTAElementPosition, WidgetViewType} from 'src/components/Widget/types';
import {isEmptyObject} from "../../../../../../shared/helpers/isEmptyObject";
import {useUpdateWidgetSettingsV2} from "../../../../../../shared/hooks/useUpdateWidgetSettingsV2";

const sizeData = [
  {
    value: 'sm',
    label: 'Small',
  },
  {
    value: 'md',
    label: 'Medium',
  },
  {
    value: 'lg',
    label: 'Large',
  },
];

export const WidgetLayoutSettings = () => {
  const dispatch = useDispatch();
  const { layout, general } = useSelector(selectWidgetSettings) ?? {};
  const {layout: layoutV2} = useSelector(selectWidgetSettingsV2) ?? {};

  const handler = (key: LayoutKey, value: LayoutValue) => {
    dispatch(widgetsActions.setLayout({ key, value }));
  };

  const {
    handler: handlerV2,
  } = useUpdateWidgetSettingsV2();


  const buyButtonHandler = (value: boolean) => {
    if(value && (!layoutV2?.previewBuyButton.buyButtonSettings || isEmptyObject(layoutV2?.previewBuyButton.buyButtonSettings))) {
      handlerV2({
        path: 'previewBuyButton.buyButtonSettings',
        value: widgetSettingsInit.buyButton,
      })
      handlerV2({
        path: 'previewBuyButton.productCardPosition',
        value: PreviewCTAElementPosition.BellowVideo,
      })
      handlerV2({
        path: 'previewBuyButton.buyButtonPosition',
        value: PreviewCTAElementPosition.BellowVideo,
      })
    }
    handlerV2({
      value,
      path: 'previewBuyButton.enable'
    })
  }

  const carouselSettings = general?.widgetViewType === WidgetViewType.carousel;

  return (
    <>
      <PropWrapper propName="Size" alignContentLeft>
        <SegmentSwitch
          value={layout?.size ?? 'sm'}
          data={sizeData}
          onChange={(val) => {
            handler('size', val)
            if(val === 'sm') {
              handlerV2({
                value: false,
                path: 'previewBuyButton.enable'
              })
            }
          }}
        />
      </PropWrapper>
      {carouselSettings && (
        <PropWrapper propName="Thumbnail radius" alignContentLeft>
          <div className={styles.slider}>
            <Slider
              value={layout?.borderRadius}
              onChange={(val) => handler('borderRadius', val)}
              w={160}
              mr={12}
              min={0}
              max={25}
            />
            <NumberInput
              color="green"
              value={typeof layout?.borderRadius === 'number' ? layout.borderRadius : 0}
              onChange={(val) => handler('borderRadius', val)}
              hideControls
              styles={() => ({
                input: { width: '42px', height: '26px', minHeight: '26px' },
              })}
              min={0}
              max={25}
              step={1}
              mr={6}
            />
            <div className={styles.slider__units}>px</div>
          </div>
        </PropWrapper>
      )}
      <PropWrapper propName="Background" alignContentLeft>
        <ColorInput
          format="hex"
          placeholder="Pick a color"
          value={layout?.background}
          onChange={(val) => handler('background', val)}
          w={171}
        />
      </PropWrapper>
      <PropWrapper propName="Navigation arrows">
        <Switch
          checked={layout?.showArrows}
          onChange={() => handler('showArrows', !layout?.showArrows)}
        />
      </PropWrapper>
      {carouselSettings && (
        <PropWrapper propName="Navigation dots">
          <Switch
            checked={layout?.showDots}
            onChange={() => handler('showDots', !layout?.showDots)}
          />
        </PropWrapper>
      )}

      <PropWithSwitchAutoScroll
        propName='Border'
        subModule={
          !layout?.showBorder ? null : (
            <BorderSubmodules
              handler={handler}
              layout={layout}
              showBorderThickness={carouselSettings}
            />
          )
        }
        checked={layout?.showBorder}
        onChange={() => handler('showBorder', !layout?.showBorder)}
      />

      <PropWithSwitchAutoScroll
        propName='Heading'
        subModule={
          !layout?.showHeading ? null : <HeadingSubmodule handler={handler} layout={layout} />
        }
        checked={layout?.showHeading}
        onChange={() => handler('showHeading', !layout?.showHeading)}
      />
      {carouselSettings && <PropWithSwitchAutoScroll
        tooltip={
        layout?.size === 'sm' ?
          'This is available only for medium and large widgets'
          : undefined
        }
        disabled={layout?.size === 'sm'}
        propName='Buy Button'
        subModule={
          !layoutV2?.previewBuyButton.enable ? null : <PreviewBuyButtonSubmodule handler={handlerV2} layout={layoutV2}/>
        }
        checked={layoutV2?.previewBuyButton.enable}
        onChange={() => buyButtonHandler(!layoutV2?.previewBuyButton.enable)}
      />}
    </>
  );
};
