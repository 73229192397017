import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayloadActionWithCallbacks } from 'src/app/store';
import { UserResponse } from './types';

interface UserState  {
  isWaitingVideosFromInstagram: boolean,
  isFBReview?: boolean,
}

const initialState: UserState = {
  isWaitingVideosFromInstagram: false,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsWaitingVideosFromInstagram(state, action: PayloadAction<boolean>) {
      state.isWaitingVideosFromInstagram = action.payload;
    },
    setIsFBReview(state, action: PayloadAction<boolean>) {
      state.isFBReview = action.payload;
    }
  },
});

export const userActions = UserSlice.actions;
