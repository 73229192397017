import { apiClient } from '../client';
import {BuyButtonIcon, BuyButtonType, WidgetBlockStatus} from "../../components/Widget/types";

export type GetWidgetsResponse = {
  name: string;
  id: string;
  user_id: string;
  type: string;
  settings: {
    general: {
      widgetViewType: string;
    };
    layout: {
      spacing: number;
      size: string;
      borderRadius: number;
      background: string;
      showArrows: boolean;
      showDots: boolean;
      showBorder: boolean;
      border: boolean;
      borderThickness: number;
      borderColor: string;
      showHeading: boolean;
      headingText: string;
      headingTextSize: number;
      headingFontWeight: string;
      headingTextColor: string;
      headingAlignment: string;
      headingBottomPadding: number;
    };
    behavior: {
      animation: string;
      autoplay: string;
    };
    buyButton: {
      type: string;
      text: string;
      icon: string;
      color: string;
      backgroundColor: string;
      borderRadius: number;
      showBorder: boolean;
      borderThickness: number;
      borderColor: string;
    };
    branding: {
      showLogo: boolean;
      type: string;
      url: string;
      fileName: string;
    };
  };
  allPages: boolean;
  pages: string[];
  page: string;
  status: string;
  blockStatus: string;
  videos: {
    mux: null;
    preview: null;
    gif: null;
    title: string;
    duration: null;
    muxAsset: null;
    url: null;
    id: string;
    createdAt: string;
    updatedAt: string;
    videoCreatedAt: string;
    type: string;
    status: 'published' | 'installed';
    userId: string;
    mediaId: string;
    mediaType: string;
    isShoppable: boolean;
  }[];
  settings_v2: {
    layout: {
      previewBuyButton: {
        enable: boolean;
        buyButtonPosition: 'OverlayOnVideo' | 'BellowVideo';
        productCardPosition: 'OverlayOnVideo' | 'BellowVideo';
        buyButtonSettings: {
          type: BuyButtonType;
          text: string | undefined;
          icon?: BuyButtonIcon;
          color: string;
          backgroundColor: string;
          borderRadius: number;
          showBorder: boolean;
          borderThickness: number;
          borderColor: string;
        }
      };
    }
  }
  blockStatuses: WidgetBlockStatus[];
}[]


export const getWidgets = () => {
  return apiClient.get<GetWidgetsResponse>('/bff/widgets');
};
