import {WidgetBlockStatus} from "../../components/Widget/types";


export const countOfWidgetBlockStatusesErrors = (status: WidgetBlockStatus[], appEmbedEnabled: boolean) => {
  let errors = 0
  if(!appEmbedEnabled) {
    errors++
  }
  status.forEach((status) => {
    if (status === 'notInstalled') {
      errors++
    }  if (status === 'widgetIsHidden') {
      errors++
    }  if (status === 'parentBlockIsHidden') {
      errors++
    }
  })
  return errors
}
