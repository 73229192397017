import {useMantineTheme} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {selectWidgetSettings} from "../../../../selectors";
import {BuyButtonKey, BuyButtonValue, widgetsActions} from "../../../../store";
import {BuyButtonSettings} from "../BuyButton/BuyButtonSettings";

export const BuyButtonProductDetails = () => {
  const dispatch = useDispatch();
  const { buyButton } = useSelector(selectWidgetSettings) ?? {};
  const handler = (key: BuyButtonKey, value: BuyButtonValue) => {
    dispatch(widgetsActions.setBuyButton({ key, value }));
  };
  return (
    <BuyButtonSettings handler={handler} buyButton={buyButton} />
  )
}
