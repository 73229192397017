import {useMutation} from "@tanstack/react-query";
import {queryKey} from "../query/keys";
import {checkWidgetBlockStatuses} from "../../api/widget/checkWidgetBlockStatuses";
import {queryClient} from "../../api/client";

export const useCheckWidgetBlockStatuses = () => {
  return useMutation({
    mutationFn: checkWidgetBlockStatuses,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey.widgetsInfinite
      });
    }
  })
}
