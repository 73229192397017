import { RootState } from 'src/app/store';

export const selectWidgetSettings = (state: RootState) => state.widgets.activeWidget?.settings;
export const selectWidgetSettingsV2 = (state: RootState) => state.widgets.activeWidget?.settings_v2;
export const selectWidgetProductDetails = (state: RootState) => state.widgets.activeWidget?.productDetails;
export const selectWidgetSettingsLayout = (state: RootState) =>
  state.widgets.activeWidget?.settings.layout;
export const selectWidgetSettingsBuyButton = (state: RootState) =>
  state.widgets.activeWidget?.settings.buyButton;
export const selectWidgetSettingsBranding = (state: RootState) =>
  state.widgets.activeWidget?.settings.branding;
export const selectWidgetSettingBehavior = (state: RootState) =>
  state.widgets.activeWidget?.settings.behavior;
export const selectWidgetSettingsGeneral = (state: RootState) =>
  state.widgets.activeWidget?.settings.general;
export const selectWidgetVideos = (state: RootState) => state.widgets.activeWidget?.videos;
export const selectActiveWidget = (state: RootState) => state.widgets.activeWidget;
export const selectWidgetById = (state: RootState, id: string) => state.widgets.widgets.find((widget) => widget.id === id);
export const selectActiveWidgetUpdatingStatus = (state: RootState) => state.widgets.activeWidgetUpdatingStatus;
export const selectCreateWidget = (state: RootState) => state.widgets.createWidget;
export const selectSelectedWidgets = (state: RootState) => state.widgets.selectedWidgets;
export const selectCheckedVideos = (state: RootState) => state.widgets.checkedVideos;
