import {consumerApiClient} from "../consumerApiClient";

interface GetVideProductResponse {
  id: string;
  product_id: string;
  product_type: string;
  price: number;
  currency: string;
  image: string;
  handle: string;
  title: string;
  myshopify_domain: string;
  status: string;
  ProductDetail: {
    id: string;
    productId: string;
    keyId: string;
    value: string;
    StoreProductDetailsKey: {
      id: string;
      userId: string;
      key: string;
      name: string;
      type: string;
      metaInformation: 'list.single_line_text_field' | 'single_line_text_field' | 'rich_text_field' | 'multi_line_text_field';
    };
  }[];
}
export const getConsumerVideoProducts = ({videoId, widgetId,...params}: {
  widgetId: string;
  videoId: string;
  skip?: number;
  take?: number;
}) => {
  return consumerApiClient.get<GetVideProductResponse[]>(`/bff/consumer-widgets/${widgetId}/videos/${videoId}/products`, {
    params,
  });
};
