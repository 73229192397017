import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import styles from './WidgetCardButton.module.scss';
import { FC } from 'react'
import {WidgetBlockStatus, WidgetStatus} from 'src/components/Widget/types';

interface WidgetCardButtonProps {
  widgetId: string
  title: string
  status: WidgetStatus
  blockStatuses: WidgetBlockStatus[]
  onPublish: () => void
  onPreview: () => void
  onUnpublish: () => void
}

export const WidgetCardButton: FC<WidgetCardButtonProps> = (props) => {
  const { widgetId, title, status, blockStatuses } = props

  const navigate = useNavigate()



  const buttonData = (() => {
    if(blockStatuses.includes(WidgetBlockStatus.notInstalled)) {
      return {
        props: {
          variant: 'filled',
          color: 'green.1'
        },
        label: 'Install',
        action: () => navigate(`/widgets/edit/${widgetId}?defaultTab=installation`)
      }
    }
    switch (status) {
      case WidgetStatus.draft:
        return {
          props: {
            variant: 'gradient',
            gradient: { from: '#00ad60', to: '#1222b2', deg: 225 },
          },
          label: 'Publish',
          action: async () => {
            props.onPublish()
          }
        }
      case WidgetStatus.published:
        return {
          props: {
            variant: 'outline',
            className: styles.publishedButton
          },
          label: 'Preview',
          action: async () => {
            props.onPreview()
            // await checkAppBlock(widgetId)
            //   .then(async ({ data }) => {
            //     if (data.status !== 'OK') {
            //       throw new Error('Widget is not installed')
            //     }
            //
            //     if (!data?.preview_url) {
            //       throw new Error('Preview URL is not available')
            //     }
            //
            //     sendLivePreviewUsed()
            //     window.open(data.preview_url, '_blank');
            //   })
            //   .catch((error) => {
            //     //TODO switch widget's status to Error
            //     console.error(error)
            //
            //     notifications.show({
            //       title: 'Preview is not available',
            //       message: 'The widget block was removed, or hidden in the Shopify theme',
            //       color: 'red.1',
            //       styles: (theme) => ({
            //         root: {
            //           backgroundColor: theme.colors.whitePrimary
            //         },
            //         title: { color: theme.colors.greyDark[1] },
            //       })
            //     })
            //   })
          }
        }

      default:
        return {
          props: {
            variant: 'filled',
            color: 'green.1'
          },
          label: 'Install',
          action: () => navigate(`/widgets/edit/${widgetId}?defaultTab=installation`)
        }
    }
  })()

  const handleAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    buttonData.action()
  }

  return (
    <Button fullWidth mt="md" onClick={handleAction} {...buttonData.props}>
      {buttonData.label}
    </Button>
  )
}
