import { Stack } from '@mantine/core';
import { ReactComponent as IconHome } from 'src/shared/icons/home.svg';
import { ReactComponent as IconTag } from 'src/shared/icons/tag.svg';
import { ReactComponent as IconTags } from 'src/shared/icons/tags.svg';
import { PageTypeButton } from './PageTypeButton';
import {LayoutSize, PageType, PreviewCTAElementPosition, WidgetCreationType} from 'src/components/Widget/types';
import React, { useContext } from 'react';
import { StepsContext } from 'src/pages/widgets/new/StepsContext';
import styles from './PageTypeStep.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { selectCreateWidget } from 'src/components/Widget/selectors';
import {sendTargetPageTypeSelectedEvent} from "../../../../shared/eventLogger/eventLogger";
import {usePostWidget} from "../../../../shared/hooks/tanstackQuery/usePostWidget";
import {useGetSingleWidget} from "../../../../shared/hooks/tanstackQuery/useGetSingleWidget";
import {useNavigate} from "react-router-dom";

type Button = {
  id: PageType;
  title: string;
  icon: JSX.Element;
};

const buttons: Button[] = [
  {
    id: PageType.home,
    title: 'Home page',
    icon: <IconHome />,
  },
  {
    id: PageType.product,
    title: 'Product pages',
    icon: <IconTag />,
  },
  {
    id: PageType.collection,
    title: 'Collection pages',
    icon: <IconTags />,
  },
];

export const PageTypeStep: React.FC<{
  onCreated?: (props:{id: string}) => void;
}> = ({onCreated}) => {
  const dispatch = useDispatch();
  const { changeStep } = useContext(StepsContext);
  const widget = useSelector(selectCreateWidget);
  const {mutate: postWidget } = usePostWidget({
    onSuccess: onCreated,
  })

  const btnHandler = (val: PageType) => {
    sendTargetPageTypeSelectedEvent({pageType: val})
    dispatch(widgetsActions.setNewWidget({ key: 'page', value: val }));
    if (!widget) return;
    const isTargetPageHome = val === 'home';


    const isCarousel = widget.viewType === 'carousel';
    const carouselBuyButton = {
      isShowBuyButtonOnPreview: true,
      previewBuyButtonPosition: PreviewCTAElementPosition.BellowVideo,
      previewProductCardPosition: PreviewCTAElementPosition.BellowVideo,
      previewBuyButtonSettings: widget.settings.buyButton
    };

    const widgetSettings: typeof widget.settings = JSON.parse(JSON.stringify(widget.settings));
    if (isCarousel) {
      widgetSettings.layout.size = LayoutSize.lg;
    } else {
      widgetSettings.layout.size = LayoutSize.sm;
    }
    postWidget({
      name: widget.name,
      page: val,
      settings: {...widgetSettings, general: {widgetViewType: widget.viewType}},
      status: widget.status,
      type: isTargetPageHome ? WidgetCreationType.manual : widget.creationType,
      allPages: true,
      ...(isCarousel ? carouselBuyButton : {})
    })
  };
  return (
    <>
      <h1 className={styles.header}>Choose a target page type</h1>
      <Stack align="center">
        {buttons.map((btn) => (
          <PageTypeButton
            key={btn.id}
            icon={btn.icon}
            title={btn.title}
            onClick={() => btnHandler(btn.id)}
          />
        ))}
      </Stack>
    </>
  );
};
