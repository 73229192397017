export const isEnum = <T extends Record<string, unknown>>(value: unknown, enumeration: T): value is T[keyof T] => {
  return Object.values(enumeration).includes(value);
}

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8];

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;


export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
    : "";

export type UnknownNestedObject = {
  [key: string]: string | boolean | number | UnknownNestedObject;
};

export type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
  { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";

export type ValueAtPath<
  T,
  P extends string
> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? ValueAtPath<T[K], Rest>
    : never
  : P extends keyof T
    ? T[P]
    : never;
