import {DataRetrieval} from "@chatpoint/widget-lib";
import {useSelector} from "react-redux";
import {selectActiveWidget} from "../../components/Widget/selectors";
import {useVideos} from "./useVideos";
import {QueryVideoFilter} from "../../api/video/getVideos";
import {WidgetCreationType, WidgetVideo} from "../../components/Widget/types";
import {useCallback} from "react";
import {getConsumerVideoProducts} from "../../api/widget/getConusmerVideoProducts";
export interface UseWidgetPreviewDataRetrievalRes{
  dataRetrieval: {
    fetchVideos: DataRetrieval['fetchVideos'];
    fetchVideoProducts: DataRetrieval['fetchVideoProducts'];
    videoCount: number;
  };
  isPreparing: boolean;
}
export const useWidgetPreviewDataRetrieval = (): UseWidgetPreviewDataRetrievalRes => {

  const activeWidget = useSelector(selectActiveWidget);

  const {data: storeVideosPages, isLoading} = useVideos({
    videoQueryFilter: QueryVideoFilter.ALL
  })

  const videos: WidgetVideo[] = activeWidget?.type === WidgetCreationType.automated ? storeVideosPages?.pages.flat(2) || [] : activeWidget?.videos || [];
  const fetchVideos: DataRetrieval['fetchVideos'] = (data) => {
    console.log('videos: ', videos)
    const mappedVideos = videos.map(video => {
      return {
        ...video,
        mp4_support: false,
        url: '',
        mux: video.mux || '',
        preview: video.preview || '',
        gif: video.gif || '',
      }
    })
    const {take, skip} = data;
    if (take && take < 0 || skip && skip < 0) {
      return [];
    }
    if(!take && skip) {
      return mappedVideos.slice(skip);
    }
    if(take && skip) {
      return mappedVideos.slice(skip, skip + take);
    }
    if(take && !skip) {
      return mappedVideos.slice(0, take);
    }
    return mappedVideos;
  }
  const fetchVideoProducts: DataRetrieval['fetchVideoProducts'] = useCallback(async (params: { videoId: string, skip?: number, take?: number }) => {
    if(!activeWidget?.id) {
      return [];
    }
    const res = await getConsumerVideoProducts({widgetId: activeWidget?.id, ...params});
    return res.data.map((product)=>{
      const mappedProduct = {
        ...product,
        productDetail: product.ProductDetail.map((productDetail) => ({
          name: productDetail.StoreProductDetailsKey.name,
          value: productDetail.value,
          metaInformation: productDetail.StoreProductDetailsKey.metaInformation
        }))
      }
      return {
        product: mappedProduct,
        videoId: params.videoId
      }
    });
  }, [activeWidget?.id])


  return {
    dataRetrieval: {
      fetchVideos,
      videoCount: videos.length,
      fetchVideoProducts,
    },
    isPreparing: isLoading,
  }
}
