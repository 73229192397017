import {LayoutPathsV2, SetLayoutV2ActionPayload, widgetsActions} from "../../components/Widget/store";
import {useDispatch} from "react-redux";

export const useUpdateWidgetSettingsV2 = () => {
  const dispatch = useDispatch();

  const handler = <Path extends LayoutPathsV2>(data: SetLayoutV2ActionPayload<Path>) => {
    dispatch(widgetsActions.setLayoutV2({
      value: data.value,
      path: data.path
    }));
  };

  return {
    handler
  }
}
