import { Badge, Tooltip } from '@mantine/core';

import { ReactComponent as IconManual } from 'src/shared/icons/settings-manual.svg';
import { ReactComponent as IconHome } from 'src/shared/icons/home.svg';
import { ReactComponent as IconTag } from 'src/shared/icons/tag.svg';
import { ReactComponent as IconTags } from 'src/shared/icons/tags.svg';
import {
  IconWand,
  IconBrandInstagram,
  IconCategory,
  IconTargetArrow,
  IconUpload
} from '@tabler/icons-react';

import styles from './WidgetCardInnerBottom.module.scss'

import { FC } from 'react'
import {PageType, WidgetBlockStatus, WidgetStatus} from 'src/components/Widget/types';

interface WidgetCardInnerBottomProps {
  source: 'instagram' | 'upload' | 'mixed' | null
  isCreatedManually: boolean
  status: WidgetStatus
  blockStatuses: WidgetBlockStatus[]
  page: PageType
  allPages: boolean
}

export const WidgetCardInnerBottom: FC<WidgetCardInnerBottomProps> = (props) => {
  const { source, isCreatedManually, status, page, allPages, blockStatuses } = props

    const badgeData = (() => {
        if(blockStatuses.includes(WidgetBlockStatus.notInstalled)) {
          return {
            props: {
              color: 'greyLight.2',
              className: styles.badge__notInstalled
            },
            label: 'Not Installed'
          }
        }
        switch (status) {
            case WidgetStatus.draft:
                return {
                    props: {
                        color: 'blue',
                        variant: 'light',
                        className: styles.badge__notPublished
                    },
                    label: 'Not Published'
                }
            case WidgetStatus.published:
                return {
                    props: {
                        color: 'green.0',
                        variant: 'light',
                        className: styles.badge__active
                    },
                    label: 'Published'
                }
            default:
                return {
                    props: {
                        color: 'greyLight.2',
                        className: styles.badge__notInstalled
                    },
                    label: 'Not Installed'
                }
        }
    })()


  const pageTooltipLabel = (() => {
    switch (page) {
      case PageType.home:
        return 'Home page'
      case PageType.product:
        return 'Product page'
      case PageType.collection:
        return 'Collection page'
      default:
        return ''
    }
  })()

  const sourceTooltipLabel = (() => {
    switch (source) {
      case 'instagram':
        return 'Videos from Instagram'
      case 'upload':
        return 'Uploaded videos'
      case 'mixed':
        return 'Mixed videos'
      default:
        return ''
    }
  })()

  return (
    <div className={styles.wrapper}>
      <Badge {...badgeData.props}>
        {badgeData.label}
      </Badge>

      <div className={styles.icons}>
        {!allPages ?
          <Tooltip
            label="Target to specific page"
            position="bottom"
            withArrow
          >
            <div className={styles.iconWrapper}><IconTargetArrow strokeWidth={1.5} /></div>
          </Tooltip>
        : null}

        {source ?
          <Tooltip
            label={sourceTooltipLabel}
            position="bottom"
            withArrow
          >
            <div className={styles.iconWrapper}>
              {source === 'instagram' ? <IconBrandInstagram strokeWidth={1.5} /> : null}
              {source === 'upload' ? <IconUpload strokeWidth={1.5} /> : null}
              {source === 'mixed' ? <IconCategory strokeWidth={1.5} /> : null}
            </div>
          </Tooltip>
        : null}

        <Tooltip
          label={pageTooltipLabel}
          position="bottom"
          withArrow
        >
          <div className={styles.iconWrapper}>
            {page === PageType.home ? <IconHome /> : null}
            {page === PageType.product ? <IconTag /> : null}
            {page === PageType.collection ? <IconTags /> : null}
          </div>
        </Tooltip>

        <Tooltip
          label={isCreatedManually ? 'Created manually' : 'Created automatically'}
          position="bottom"
          withArrow
        >
          <div className={styles.iconWrapper}>
            {isCreatedManually ? <IconManual /> : <IconWand color="#C1C2C5" strokeWidth={1.5} />}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
